import React, { useEffect, useState } from "react";
import "./photos.css";
import { app } from "../../components/firebase/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

// icons
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import {
  RiArrowRightDoubleLine,
  RiArrowRightSLine,
  RiArrowLeftDoubleLine,
  RiArrowLeftSLine,
} from "react-icons/ri";

function Photos() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [uid, setUid] = useState();
  const [urls, setUrls] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [collectionSize, setCollectionSize] = useState(0);

  const db = app.firestore();
  const location = useLocation(); // Récupérez l'objet location de react-router-dom
  const navigation = useNavigate(); // Récupérez l'objet location de react-router-dom

  useEffect(() => {
    setImages([]);
    var queryParams = queryString.parse(location.search); // Récupérez les paramètres d'URL
    queryParams = parseInt(queryParams.p || 1);
    setCurrentPage(queryParams); // Utilisez le paramètre "p" de l'URL, sinon 1 par défaut
    // Récupérez les 40 premières photos de la collection 'image'
    const fetchImages = async () => {
      try {
        await db
          .collection("info")
          .doc("image")
          .get()
          .then((data) => {
            setCollectionSize(data.data().count);
          });
        var querySnapshot = [];
        if (queryParams > 1) {
          const querySnapshotPag = await db
            .collection("image")
            .orderBy("index", "desc") // Tri par ordre décroissant de la propriété "index"
            .limit(40 * currentPage)
            .get();
          querySnapshot = await db
            .collection("image")
            .orderBy("index", "desc")
            .startAfter(querySnapshotPag.docs[querySnapshotPag.docs.length - 1])
            .limit(40)
            .get();
        } else {
          querySnapshot = await db
            .collection("image")
            .orderBy("index", "desc") // Tri par ordre décroissant de la propriété "index"
            .limit(40)
            .get();
        }
        const imageUrls = await Promise.all(
          await querySnapshot.docs.map(async (doc) => {
            const uid = doc.data().index;
            // Construisez le chemin d'accès complet à l'image dans Storage
            const imagePath = `images/${uid}.jpg`;
            // Récupérez l'URL de l'image depuis Storage
            const imageUrl = await firebase
              .storage()
              .ref()
              .child(imagePath)
              .getDownloadURL();
            return { ...doc.data(), url: imageUrl };
          })
        );
        setImages(imageUrls.sort((a, b) => b.index - a.index));
        const initialUrls = imageUrls.map((item) => item.url);
        setUrls(initialUrls.sort((a, b) => b.index - a.index));
      } catch (error) {
        console.error("Erreur lors de la récupération des images :", error);
      }
    };
    fetchImages();
  }, [currentPage, db, location.search]);

  const handleThumbnailClick = (image, index, uid) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setUid(uid);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setCurrentIndex(0);
  };

  const handlePrev = (index) => {
    var uid = index - 1;
    if (index >= 0) {
      setSelectedImage(urls[index - 1]);
      setCurrentIndex(index - 1);
      setUid(images[uid].indexString);
    }
  };

  const handleNext = (index) => {
    var uid = index + 1;
    if (index <= 40) {
      setSelectedImage(urls[index + 1]);
      setCurrentIndex(index + 1);
      setUid(images[uid].indexString);
    }
  };

  // Gestion de la pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    navigation("/photos?p=" + pageNumber);
  };
  return (
    <div className="Photos">
      <div className="header">
        <div className="title text-6xl">Photos.</div>
      </div>
      <div className="wrapper">
        <div className="gallery">
          <div className="gallery-container">
            {images.map((image, index) => (
              <div key={index} className="gallery-item">
                <img
                  src={image.url}
                  alt={`${index + 1}`}
                  onClick={() =>
                    handleThumbnailClick(image.url, index, image.index)
                  }
                />
              </div>
            ))}
          </div>

          {selectedImage && (
            <div className="overlay">
              <div className="image-modal">
                <img src={selectedImage} alt="agrandie" />
                <div className="bg" onClick={() => handleClose()}></div>
              </div>
              <div className="bottom-bar">
                <div className="container">
                  <div
                    className="icon-left icon"
                    onClick={() => handlePrev(currentIndex)}
                  >
                    <FaArrowLeftLong />
                  </div>
                  <div className="index">@DD{uid}</div>
                  <div
                    className="icon-right icon"
                    onClick={() => handleNext(currentIndex)}
                  >
                    <FaArrowRightLong />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pagination">
        {currentPage === 1 ||
        currentPage === 2 ||
        currentPage === 3 ||
        Math.floor(collectionSize / 40) < currentPage ? (
          <div className="container">
            <button
              onClick={() => handlePageChange(1)}
              className={currentPage === 1 ? "active" : "null"}
            >
              1
            </button>
            <button
              onClick={() => handlePageChange(2)}
              className={currentPage === 2 ? "active" : "null"}
            >
              2
            </button>
            <button
              onClick={() => handlePageChange(3)}
              className={currentPage === 3 ? "active" : "null"}
            >
              3
            </button>
            <button onClick={() => handlePageChange(4)}>4</button>
            <button onClick={() => handlePageChange(5)}>5</button>
            <button onClick={() => handlePageChange(currentPage + 1)}>
              <RiArrowRightSLine className="icon" />
            </button>
            <button
              onClick={() => handlePageChange(Math.floor(collectionSize / 40))}
            >
              <RiArrowRightDoubleLine className="icon" />
            </button>
          </div>
        ) : Math.floor(collectionSize / 40) - 2 <= currentPage ? (
          <div className="container">
            <button onClick={() => handlePageChange(1)}>
              <RiArrowLeftDoubleLine className="icon" />
            </button>
            <button onClick={() => handlePageChange(currentPage - 1)}>
              <RiArrowLeftSLine className="icon" />
            </button>
            <button
              onClick={() =>
                handlePageChange(Math.floor(collectionSize / 40) - 4)
              }
            >
              {Math.floor(collectionSize / 40) - 4}
            </button>
            <button
              onClick={() =>
                handlePageChange(Math.floor(collectionSize / 40) - 3)
              }
            >
              {Math.floor(collectionSize / 40) - 3}
            </button>
            <button
              onClick={() =>
                handlePageChange(Math.floor(collectionSize / 40) - 2)
              }
              className={
                currentPage === Math.floor(collectionSize / 40) - 2
                  ? "active"
                  : "null"
              }
            >
              {Math.floor(collectionSize / 40 - 2)}
            </button>
            <button
              onClick={() =>
                handlePageChange(Math.floor(collectionSize / 40) - 1)
              }
              className={
                currentPage === Math.floor(collectionSize / 40) - 1
                  ? "active"
                  : "null"
              }
            >
              {Math.floor(collectionSize / 40) - 1}
            </button>
            <button
              onClick={() => handlePageChange(Math.floor(collectionSize / 40))}
              className={
                currentPage === Math.floor(collectionSize / 40)
                  ? "active"
                  : "null"
              }
            >
              {Math.floor(collectionSize / 40)}
            </button>
          </div>
        ) : (
          <div className="container">
            <button onClick={() => handlePageChange(1)}>
              <RiArrowLeftDoubleLine className="icon" />
            </button>
            <button onClick={() => handlePageChange(currentPage - 1)}>
              <RiArrowLeftSLine className="icon" />
            </button>
            <button onClick={() => handlePageChange(currentPage - 1)}>
              {currentPage - 1}
            </button>
            <button className="active">{currentPage}</button>
            <button onClick={() => handlePageChange(currentPage + 1)}>
              {currentPage + 1}
            </button>
            <button onClick={() => handlePageChange(currentPage + 1)}>
              <RiArrowRightSLine className="icon" />
            </button>
            <button
              onClick={() => handlePageChange(Math.floor(collectionSize / 40))}
            >
              <RiArrowRightDoubleLine className="icon" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Photos;
